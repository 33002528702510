import { Link, useLocation  } from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useProfile, useModal, useEnd} from "../context";
import Login from "./modals/Login";
import Info from "./modals/Info";
import ChangePass from "./modals/ChangePass";
import classNames from "classnames";
import useEffectOnce from "../hooks/useEffectOnce";
import Lock from "./modals/Lock";

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [lkNav, setLkNav] = useState(false);
    const location = useLocation();
    const {end} = useEnd();
    const {profile} =  useProfile();

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffectOnce(()=>{
       if(!localStorage.getItem('end')){
           show(<Lock/>, "modal--form modal--login");
           localStorage.setItem('end', '1');
       }
    });

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }, [location.pathname]);

    useEffect(()=>{
        if(!profile.isLoading && Object.keys(profile.data).length > 0){

        }
    }, [profile]);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > 5){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }

    return (
        <div id="header"
             className={
                 classNames('header fadeIn animated', (inner ? ' header--inner' : ''),
                     (nav ? ' active header--open' : ''),
                     (fixed ? ' header--fixed' : ''),
                     (!profile.loading ? ' header--loaded' : '')
                 )}>
            <div className="container d-flex align-items-start justify-content-between">
                <div className="header__left d-flex align-items-center">
                    {!inner && (
                        <div onClick={()=>{
                            goToAnchor('promo');
                        }} className="logo">
                            <img src={require("../assets/img/logo.svg").default}/>
                        </div>
                    )}
                    {inner && (
                        <Link to="/" className="logo">
                            <img src={require("../assets/img/logo.svg").default}/>
                        </Link>
                    )}
                </div>
                <div className="nav__box fadeIn animated">
                    <div className="nav__bl">
                        <div className="nav__menu">
                            <ul>
                                <li>
                                    <Link to="/#promo" onClick={(e)=>{
                                        if(window.ym) {
                                            window.ym(97909310,'reachGoal', 'menu_head');
                                        }
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('promo');
                                        }
                                    }}>Главная</Link>
                                </li>
                                <li>
                                    <Link to="/#partners" onClick={(e)=>{
                                        if(window.ym) {
                                            window.ym(97909310,'reachGoal', 'menu_head');
                                        }
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('partners');
                                        }
                                    }}>сети-партнеры</Link>
                                </li>
                                <li>
                                    <Link to="/#prizes" onClick={(e)=>{
                                        if(window.ym) {
                                            window.ym(97909310,'reachGoal', 'menu_head');
                                        }
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('prizes');
                                        }
                                    }}>призы</Link>
                                </li>
                                <li>
                                    <Link to="/#winners" onClick={(e)=>{
                                        if(window.ym) {
                                            window.ym(97909310,'reachGoal', 'menu_head');
                                        }
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('winners');
                                        }
                                    }}>Победители</Link>
                                </li>
                                <li>
                                    <li>
                                        <Link to="/#faq" onClick={(e)=>{
                                            if(window.ym) {
                                                window.ym(97909310,'reachGoal', 'menu_head');
                                            }
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('faq');
                                            }
                                        }}>Вопрос-ответ</Link>
                                    </li>
                                </li>
                            </ul>
                        </div>
                        <div className="nav__bottom mb-only">
                            {Object.keys(profile.data).length === 0 && (
                                <div onClick={()=>{
                                    show(<Login/>, "modal--form modal--login");
                                }} className="btn-ct text-nowrap btn--full btn-ct--small btn-ct--border d-flex align-items-center justify-content-center">
                                    <i className="icon__user">
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5396 8.1525C9.4646 8.145 9.3746 8.145 9.2921 8.1525C7.5071 8.0925 6.0896 6.63 6.0896 4.83C6.0896 2.9925 7.5746 1.5 9.4196 1.5C11.2571 1.5 12.7496 2.9925 12.7496 4.83C12.7421 6.63 11.3246 8.0925 9.5396 8.1525Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.78947 10.92C3.97447 12.135 3.97447 14.115 5.78947 15.3225C7.85197 16.7025 11.2345 16.7025 13.297 15.3225C15.112 14.1075 15.112 12.1275 13.297 10.92C11.242 9.5475 7.85947 9.5475 5.78947 10.92Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </i>
                                    <span className="text-nowrap">Личный кабинет</span>
                                </div>
                            )}

                            {Object.keys(profile.data).length > 0 && (
                                <>
                                    <Link to="/cabinet" className="btn-ct btn--full text-nowrap btn-ct--small btn-ct--border d-flex align-items-center justify-content-center">
                                        <i className="icon__user">
                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.5396 8.1525C9.4646 8.145 9.3746 8.145 9.2921 8.1525C7.5071 8.0925 6.0896 6.63 6.0896 4.83C6.0896 2.9925 7.5746 1.5 9.4196 1.5C11.2571 1.5 12.7496 2.9925 12.7496 4.83C12.7421 6.63 11.3246 8.0925 9.5396 8.1525Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M5.78947 10.92C3.97447 12.135 3.97447 14.115 5.78947 15.3225C7.85197 16.7025 11.2345 16.7025 13.297 15.3225C15.112 14.1075 15.112 12.1275 13.297 10.92C11.242 9.5475 7.85947 9.5475 5.78947 10.92Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </i>
                                        <span className="text-nowrap">Личный кабинет</span>
                                    </Link>
                                    <div onClick={()=>{
                                        localStorage.removeItem('auth_key');
                                        window.location.reload();
                                    }} className="btn__lk-dropdown-it d-flex align-items-center">
                                        <i className="icon__logout">
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.083 13.7C12.8247 16.7 11.283 17.925 7.90801 17.925L7.79967 17.925C4.07467 17.925 2.58301 16.4333 2.58301 12.7083L2.58301 7.28332C2.58301 3.55832 4.07467 2.06665 7.79967 2.06665L7.90801 2.06665C11.258 2.06665 12.7997 3.27498 13.0747 6.22498" stroke="#D0AA6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M7.99994 10L17.4833 10" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M15.625 12.7916L18.4166 9.99992L15.625 7.20825" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </i>
                                        <span>
                                           Выход
                                       </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="header__right d-flex">
                    {!profile.loading && (
                        <div className="header__right-it">
                            {Object.keys(profile.data).length === 0 && (
                                <>
                                    <div onClick={()=>{
                                        show(<Login/>, "modal--form modal--login");
                                    }} className="btn-ct text-nowrap btn-ct--small btn-ct--border d-flex align-items-center justify-content-center desk-only">
                                        <i className="icon__user">
                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.5396 8.1525C9.4646 8.145 9.3746 8.145 9.2921 8.1525C7.5071 8.0925 6.0896 6.63 6.0896 4.83C6.0896 2.9925 7.5746 1.5 9.4196 1.5C11.2571 1.5 12.7496 2.9925 12.7496 4.83C12.7421 6.63 11.3246 8.0925 9.5396 8.1525Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M5.78947 10.92C3.97447 12.135 3.97447 14.115 5.78947 15.3225C7.85197 16.7025 11.2345 16.7025 13.297 15.3225C15.112 14.1075 15.112 12.1275 13.297 10.92C11.242 9.5475 7.85947 9.5475 5.78947 10.92Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </i>
                                        <span className="text-nowrap">Личный кабинет</span>
                                    </div>
                                    <div onClick={()=>{
                                        show(<Login/>, "modal--form modal--login");
                                    }} className="btn__lk-mb mb-only">
                                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1" y="1" width="43" height="43" rx="21.5" stroke="white" strokeWidth="2"/>
                                            <path d="M22.6467 21.4643C22.5551 21.4552 22.4451 21.4552 22.3442 21.4643C20.1626 21.391 18.4301 19.6035 18.4301 17.4035C18.4301 15.1577 20.2451 13.3335 22.5001 13.3335C24.7459 13.3335 26.5701 15.1577 26.5701 17.4035C26.5609 19.6035 24.8284 21.391 22.6467 21.4643Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M18.0634 24.8465C15.8451 26.3315 15.8451 28.7515 18.0634 30.2273C20.5842 31.914 24.7184 31.914 27.2392 30.2273C29.4576 28.7423 29.4576 26.3223 27.2392 24.8465C24.7276 23.169 20.5934 23.169 18.0634 24.8465Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </>

                            )}

                            {Object.keys(profile.data).length > 0 && (
                                <>
                                    <div className={'btn__lk desk-only' + (lkNav ? ' active' : '')}>
                                        <Link to="/cabinet" className="btn-ct text-nowrap btn-ct--small btn-ct--border d-flex align-items-center justify-content-center">
                                            <i className="icon__user">
                                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.5396 8.1525C9.4646 8.145 9.3746 8.145 9.2921 8.1525C7.5071 8.0925 6.0896 6.63 6.0896 4.83C6.0896 2.9925 7.5746 1.5 9.4196 1.5C11.2571 1.5 12.7496 2.9925 12.7496 4.83C12.7421 6.63 11.3246 8.0925 9.5396 8.1525Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M5.78947 10.92C3.97447 12.135 3.97447 14.115 5.78947 15.3225C7.85197 16.7025 11.2345 16.7025 13.297 15.3225C15.112 14.1075 15.112 12.1275 13.297 10.92C11.242 9.5475 7.85947 9.5475 5.78947 10.92Z" stroke="#D0AA6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </i>
                                            <span className="text-nowrap">Личный кабинет</span>
                                        </Link>
                                        <div className="btn__lk-dropdown">
                                            <div className="btn__lk-dropdown-it">
                                                <span>{profile.data.profile.surname + ' ' + profile.data.profile.name}</span>
                                            </div>
                                            <div className="btn__lk-dropdown-it">
                                                <span>{profile.data.profile.email}</span>
                                            </div>
                                            <div className="btn__lk-dropdown-it">
                                                <span>+{numberWithSpaces(profile.data.profile.phone)}</span>
                                            </div>
                                            <div onClick={()=>{
                                                localStorage.removeItem('auth_key');
                                                window.location.reload();
                                            }} className="btn__lk-dropdown-it d-flex align-items-center">
                                                <i className="icon__logout">
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.2288 13.8199C12.9704 16.8199 11.4288 18.0449 8.05376 18.0449L7.94543 18.0449C4.22043 18.0449 2.72876 16.5532 2.72876 12.8282L2.72876 7.40319C2.72876 3.67819 4.22043 2.18652 7.94543 2.18652L8.05376 2.18652C11.4038 2.18652 12.9454 3.39486 13.2204 6.34486" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M8.14557 10.1199L17.6289 10.1199" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M15.7708 12.9115L18.5625 10.1198L15.7708 7.32812" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </i>
                                                <span>
                                                   Выход
                                               </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/cabinet" className="btn__lk-mb mb-only">
                                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1" y="1" width="43" height="43" rx="21.5" stroke="white" strokeWidth="2"/>
                                            <path d="M22.6467 21.4643C22.5551 21.4552 22.4451 21.4552 22.3442 21.4643C20.1626 21.391 18.4301 19.6035 18.4301 17.4035C18.4301 15.1577 20.2451 13.3335 22.5001 13.3335C24.7459 13.3335 26.5701 15.1577 26.5701 17.4035C26.5609 19.6035 24.8284 21.391 22.6467 21.4643Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M18.0634 24.8465C15.8451 26.3315 15.8451 28.7515 18.0634 30.2273C20.5842 31.914 24.7184 31.914 27.2392 30.2273C29.4576 28.7423 29.4576 26.3223 27.2392 24.8465C24.7276 23.169 20.5934 23.169 18.0634 24.8465Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </Link>
                                </>
                            )}

                        </div>
                    )}

                </div>
                <div onClick={()=>{
                    setNav(!nav);
                    if(document.body.classList.contains('overflow')) {
                        document.body.classList.remove('overflow');
                    }else {
                        document.body.classList.add('overflow');
                    }
                }} className="nav__toggle d-flex align-items-center justify-content-center mb-only">
                    {!nav && (
                        <i className="icon__bars fadeIn animated">
                            <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="30" height="2" rx="1" fill="white"/>
                                <rect y="10.5" width="30" height="2" rx="1" fill="white"/>
                                <rect y="20.5" width="30" height="2" rx="1" fill="white"/>
                            </svg>
                        </i>
                    )}

                    {nav && (
                        <i className="icon__close fadeIn animated">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.372559" y="21.2131" width="30" height="2" rx="1" transform="rotate(-45 0.372559 21.2131)" fill="white"/>
                                <rect width="30" height="2" rx="1" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 23 21.2131)" fill="white"/>
                            </svg>
                        </i>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
