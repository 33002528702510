import {useEnd, useModal, useProfile} from "../../context";
import Login from "./../modals/Login";
import Lock from "./../modals/Lock";
import React from "react";

function CodesWidget({classesList = null, icon = false, title = 'Зарегистрировать чек', id = null, closeNav = null}) {
    const {profile} =  useProfile();
    const { show, hide} = useModal();

    return (
        <>
            <a onClick={()=>{
                show(<Lock/>, "modal--form modal--login");

                if(closeNav){
                    closeNav();
                }
                /*if(profile.loggedIn && profile.data.profile.userUuid) {
                    if(window.promoTools) {
                        window.promoTools.checkGrabberWidget.settings = {
                            skin: 'custom',
                            api: process.env.REACT_APP_API_PROXY,
                            method: 'widgetsReceipts',
                            userUuid: profile.data.profile.userUuid,
                            fields: {
                                manual: {
                                    photos: {
                                        required: false,
                                    },
                                },
                            },
                            i18n: {
                                qr: {
                                    title: 'Сканирование',
                                    subTitle: 'Наведите камеру на QR-код',
                                    btnQr: 'Сканировать QR-код чека',
                                    btnManual: 'Ввести данные вручную',
                                    btnPhotos: 'Загрузить фото чека',
                                    btnBackScanner: 'Назад к сканеру чеков',
                                    cameraError: {
                                        title: '<i class="icon__error"></i><div class="check-grabber__title">нет доступа <br/>к камере</div><div>Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства. Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную</div>',
                                    },
                                },
                                manual: {
                                    title: 'Ввести данные <br/> с чека вручную',
                                    subTitle: '',
                                },
                                photos: {
                                    title: 'Загрузка чека',
                                    subTitle: 'Добавьте фото чека',
                                    btnPhotos: 'Отправить',
                                },
                                pending: {
                                    title: 'Подождите!',
                                    subTitle: 'Идет отправка чека',
                                },
                                fail: {
                                    title: 'Не удалось <br/>отправить чек!',
                                },
                                success: {
                                    title: '<i class="icon__success"></i><div>ваш чек успешно <br/>загружен</div>',
                                    subTitle: 'Проверка чека может занимать до&nbsp;72&nbsp;часов. После того, как чек пройдет модерацию, вы&nbsp;получите письмо на&nbsp;почту, указанную при регистрации',
                                },
                            },
                            events: {
                                onSentSuccess(res) {
                                    if(window.ym) {
                                        window.ym(97909310,'reachGoal', 'receipt_registration_success');

                                        const script = document.createElement("script");
                                        script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                                            '({ sid: 228586, bt: 62, sz: "successful_check_registration"},{ id: "", gid1: "", yid1: "" });';
                                        document.head.appendChild(script);
                                    }

                                    if(window.dataLayer) {
                                        window.dataLayer.push({
                                            'event': 'customEvent',
                                            'eventCategory': '9RegCheck'
                                        });
                                    }

                                    if(window.generalPixel) {
                                        window.generalPixel.postClick('a10');
                                    }

                                    let elUp1 = document.getElementById('update-codes');
                                    if(elUp1){
                                        elUp1.click();
                                    }

                                    let elUp2 = document.getElementById('inc-codes');
                                    if(elUp2){
                                        elUp2.click();
                                    }
                                },
                                onClosePopup() {
                                    if(window.ym) {
                                        window.ym(97909310,'reachGoal', 'left_receipt_registration');

                                        const script = document.createElement("script");
                                        script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                                            '({ sid: 228586, bt: 62, sz: "abandoned_check_registration"},{ id: "", gid1: "", yid1: "" });';
                                        document.head.appendChild(script);
                                    }

                                    if(window.dataLayer) {
                                        window.dataLayer.push({
                                            'event': 'customEvent',
                                            'eventCategory': '10noRegCheck'
                                        });
                                    }
                                },
                            },
                        };
                        window.promoTools.checkGrabberWidget.show();
                    }
                }else {
                    show(<Login/>, "modal--form modal--login");
                }

                if(id === 'promo' && window.ym) {
                    window.ym(97909310,'reachGoal', 'register_receipt_1_screen');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "register_check_first_screen"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);

                    if(window.generalPixel) {
                        window.generalPixel.postClick('a1');
                    }

                    if(window.dataLayer) {
                        window.dataLayer.push({
                            'event': 'customEvent',
                            'eventCategory': '1Click'
                        });
                    }

                }

                if(id === 'prizes' && window.ym) {
                    window.ym(97909310,'reachGoal', 'register_receipt_2_screen');

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "register_check_second_screen"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);

                    if(window.generalPixel) {
                        window.generalPixel.postClick('a2');
                    }

                    if(window.dataLayer) {
                        window.dataLayer.push({
                            'event': 'customEvent',
                            'eventCategory': '2Click'
                        });
                    }
                }

                if((id === 'lk' || id === 'week-prize' || id === 'main-prize' || id === 'special-prize') && window.ym) {
                    window.ym(97909310,'reachGoal', 'register_receipt_cabinet');

                    let goalName = 'load_check_first_screen';
                    let goalName2 = '3Click';

                    switch (id) {
                        case 'week-prize':
                            goalName = 'load_check_weekly_prize';
                            goalName2 = '4Click';
                            break;
                        case 'main-prize':
                            goalName = 'load_check_main_prize';
                            goalName2 = '6Click';
                            break;
                        case 'special-prize':
                            goalName = 'load_check_special_prize';
                            goalName2 = '5Click';
                            break;
                    }

                    if(window.dataLayer) {
                        window.dataLayer.push({
                            'event': 'customEvent',
                            'eventCategory': goalName2
                        });
                    }

                    const script = document.createElement("script");
                    script.innerHTML = '!function(e,n){function o(e,n,o){n=n||"&",o=o||"=";var d=[];for(var r in e)e.hasOwnProperty(r)&&d.push(r+o+encodeURIComponent(e[r]));return d.join(n)}function d(e,n){var o=e.cookie.match("(^|;) ?"+n+"=([^;]*)(;|$)");return o?decodeURIComponent(o[2]):null}var r,t,i,c,u;r=e,t=n,i=document.domain,c={tail256:document.referrer||"unknown"},void 0!==(u=function(e){var n={};if(e){var o=e.split("&");for(var d in o)if(o.hasOwnProperty(d)){var r=o[d].split("=");void 0!==r[0]&&void 0!==r[1]&&(n[r[0]]=decodeURIComponent(r[1]))}}return n}(window.location.search.substring(1))).adrclid&&(r.fsid=u.adrclid),null!==d(document,"adrcid")&&(r.cid=d(document,"adrcid")),t&&t.id&&null!==d(document,t.id)&&(r.suid=i+"_"+encodeURIComponent(d(document,t.id))),t&&t.gid1?r.gid1=t.gid1:null!==d(document,"_ga")&&(r.gid1=encodeURIComponent(d(document,"_ga"))),t&&t.yid1?r.yid1=t.yid1:null!==d(document,"_ym_uid")&&(r.yid1=encodeURIComponent(d(document,"_ym_uid"))),r.loc=encodeURIComponent(window.location.href),r.custom&&(r.custom=o(r.custom,";")),function(e,n){!function(e){if(e=e.split("![rnd]").join(~~(1e6*Math.random())),document.createElement&&document.body){var n=document.createElement("img");n.style.position="absolute",n.style.display="none",n.style.width=n.style.height="0px",n.setAttribute("referrerpolicy","no-referrer-when-downgrade"),n.src=e,document.body.appendChild(n)}else{var o=new Image;o.setAttribute("referrerpolicy","no-referrer-when-downgrade"),o.src=e}}("https://ad.adriver.ru/cgi-bin/rle.cgi?"+e+"&rnd=![rnd]"+(n?"&"+n:""))}(o(r),o(c))}\n' +
                        '({ sid: 228586, bt: 62, sz: "' + goalName + '"},{ id: "", gid1: "", yid1: "" });';
                    document.head.appendChild(script);

                    if(window.generalPixel && id === 'lk') {
                        window.generalPixel.postClick('a5');
                    }

                    if(window.generalPixel && id === 'week-prize') {
                        window.generalPixel.postClick('a7');
                    }

                    if(window.generalPixel && id === 'main-prize') {
                        window.generalPixel.postClick('a9');
                    }

                    if(window.generalPixel && id === 'special-prize') {
                        window.generalPixel.postClick('a8');
                    }
                }*/
            }} className={classesList} id="rec-trigger-show">
                <span>{title}</span>
                {icon && (
                    <i className="btn__arrow">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#532010"/>
                            <path d="M17.4078 12.7778L24.0744 19.4445L17.4078 26.1112" stroke="#E4C9B5" strokeWidth="2"/>
                        </svg>
                    </i>
                )}
            </a>
        </>
    );
}

export default CodesWidget;
